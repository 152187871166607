import { Box } from "@material-ui/core";

const Logo = (props) => (
  <Box sx={{ width: 33, height: 33 }}>
    <img
      alt="Logo"
      src="/static/logo.png"
      {...props}
      style={{ width: "100%", height: "auto" }}
    />
  </Box>
);

export default Logo;

import PropTypes from "prop-types";
import { TableCell, TableRow, Button, Box } from "@material-ui/core";
import { useDispatch } from "react-redux";

import moment from "moment";

const text = {
  display: "-webkit-box",
  overflow: "hidden",
  WebkitBoxOrient: "vertical",
  whiteSpace: "normal",
};

const DealTableBody = ({ data, openDeleteDealModal }) => {
  const dispatch = useDispatch();

  const returnStatus = (status) => {
    switch (status) {
      case 0:
        return "Pending";
      case 10:
        return "Available";
      case -20:
        return "Canceled";

      default:
        return "";
    }
  };

  return (
    <>
      <TableRow hover>
        <TableCell>{data?.user?.nameSlug}</TableCell>
        <TableCell align="center">{data?.id}</TableCell>
        <TableCell size="small">
          <Box
            sx={{
              ...text,
              WebkitLineClamp: 2,
              maxWidth: "200px",
            }}
          >
            <p>{data.title}</p>
          </Box>
        </TableCell>
        <TableCell>{moment(data.createdAt).format("DD/MM/YYYY")}</TableCell>
        <TableCell>{returnStatus(data.status)}</TableCell>
        <TableCell>
          <Box
            sx={{
              ...text,
              WebkitLineClamp: 1,
              maxWidth: "230px",
            }}
          >
            <a href={data.link} target="_blank" rel="noopener noreferrer">
              {data.link}
            </a>
          </Box>
        </TableCell>
        <TableCell align="center">
          <Button
            color="warning"
            variant="text"
            onClick={() => openDeleteDealModal(data.id)}
          >
            Delete
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

DealTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  openDeleteDealModal: PropTypes.func.isRequired,
};

export default DealTableBody;

const getUrlParams = () => {
  const searchParams = window.location.search;
  const searchParamsArr = searchParams.length
    ? searchParams.slice(1).split("&")
    : [];
  let searchParamsObj = {};

  for (let param of searchParamsArr) {
    let arr = param.split("=");
    if (arr.length === 2) {
      searchParamsObj[arr[0]] = decodeURIComponent(arr[1]);
    }
  }

  return searchParamsObj;
};

export default getUrlParams;

export const types = {
  SHOW_LOADER: "APP/SHOW_LOADER",
  HIDE_LOADER: "APP/HIDE_LOADER",
  SET_ALERT: "APP/SET_ALERT",
};

export function showLoader() {
  return {
    type: types.SHOW_LOADER,
  };
}

export function hideLoader() {
  return {
    type: types.HIDE_LOADER,
  };
}

export const setAlertAction = (data) => {
  return {
    type: types.SET_ALERT,
    data,
  };
};

import { useEffect } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  List,
  Typography,
} from "@material-ui/core";
import {
  // AlertCircle as AlertCircleIcon,
  // BarChart as BarChartIcon,
  // Lock as LockIcon,
  // Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  ShoppingCart as ShoppingCartIcon,
  // User as UserIcon,
  // UserPlus as UserPlusIcon,
  Users as UsersIcon,
  LogOut as LogOutIcon,
} from "react-feather";
import NavItem from "./NavItem";
import { useAppContext } from "src/context/AppContext";
import getInitials from "src/utils/getInitials";

const items = [
  // {
  //   href: "/app/dashboard",
  //   icon: BarChartIcon,
  //   title: "Dashboard",
  // },
  {
    href: "/app/customers",
    icon: UsersIcon,
    title: "Customers",
  },
  {
    href: "/app/orders",
    icon: ShoppingCartIcon,
    title: "Orders",
  },
  {
    href: "/app/deals",
    icon: ShoppingBagIcon,
    title: "Deals",
  },

  // {
  //   href: "/app/account",
  //   icon: UserIcon,
  //   title: "Account",
  // },
  // {
  //   href: "/app/settings",
  //   icon: SettingsIcon,
  //   title: "Settings",
  // },
  // {
  //   href: "/login",
  //   icon: LockIcon,
  //   title: "Login",
  // },
  // {
  //   href: "/register",
  //   icon: UserPlusIcon,
  //   title: "Register",
  // },
  // {
  //   href: "/404",
  //   icon: AlertCircleIcon,
  //   title: "Error",
  // },
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userInfo, logout } = useAppContext();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const logoutHandler = (e) => {
    e.preventDefault();
    logout();

    navigate("/login");
  };

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          p: 2,
        }}
      >
        <Avatar
          component={RouterLink}
          src={userInfo?.avatar}
          sx={{
            cursor: "pointer",
            width: 64,
            height: 64,
          }}
          to="/app/account"
        >
          {getInitials(userInfo?.nameSlug)}
        </Avatar>
        <Typography color="textPrimary" variant="h5">
          {userInfo?.nameSlug}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {userInfo?.bio || "Admin"}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}

          <NavItem
            href={"/login"}
            title={"Log Out"}
            icon={LogOutIcon}
            onClick={logoutHandler}
          />
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <Box sx={{ display: { xs: "block", lg: "none", xl: "none" } }}>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256,
            },
          }}
        >
          {content}
        </Drawer>
      </Box>
      <Box sx={{ display: { xs: "none", lg: "block", xl: "block" } }}>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: "calc(100% - 64px)",
            },
          }}
        >
          {content}
        </Drawer>
      </Box>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default DashboardSidebar;

import { types } from "./appActions";

const initialState = {
  loading: false,
  appAlert: {
    status: "",
    message: "",
  },
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_LOADER:
      return { ...state, loading: true };

    case types.HIDE_LOADER:
      return { ...state, loading: false };

    case types.SET_ALERT:
      return {
        ...state,
        appAlert: action.data,
      };

    default:
      return state;
  }
};

import PropTypes from "prop-types";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Typography,
  Avatar,
  Box,
  Checkbox,
  TableCell,
  TableRow,
  Button,
} from "@material-ui/core";
import getInitials from "src/utils/getInitials";
import { blockUser, unblockUser } from "src/api";
import { setAlertAction } from "src/redux/app/appActions";
import moment from "moment";

const CustomerTableBody = ({
  customer,
  selectedCustomerIds,
  handleSelectOne,
}) => {
  const dispatch = useDispatch();

  const [activeStatus, setActiveStatus] = useState(customer.status === 0);

  const changeUserStatusHandler = async () => {
    try {
      setActiveStatus((prev) => !prev);
      if (activeStatus) await blockUser(customer?.nameSlug);
      else await unblockUser(customer?.nameSlug);
    } catch (error) {
      dispatch(
        setAlertAction({
          status: "error",
          message: "Oops, some problem with the api.",
        })
      );
      setActiveStatus((prev) => !prev);
    }
  };

  return (
    <>
      <TableRow
        hover
        selected={selectedCustomerIds.indexOf(customer.id) !== -1}
      >
        <TableCell padding="checkbox">
          <Checkbox
            checked={selectedCustomerIds.indexOf(customer.id) !== -1}
            onChange={(event) => handleSelectOne(event, customer.id)}
            value="true"
          />
        </TableCell>
        <TableCell>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
            }}
          >
            <Avatar src={customer?.avatar} sx={{ mr: 2 }}>
              {getInitials(customer?.name)}
            </Avatar>
            <a
              href={`https://dealtr.ee/${customer.nameSlug}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography color="textPrimary" variant="body1">
                {customer.name || customer.nameSlug}
              </Typography>
            </a>
          </Box>
        </TableCell>
        <TableCell>{customer.email}</TableCell>
        <TableCell>{customer.id}</TableCell>
        <TableCell>{moment(customer.createdAt).format("DD/MM/YYYY")}</TableCell>
        <TableCell>{activeStatus ? "Active" : "Inactive"}</TableCell>
        <TableCell>
          <Button
            color={activeStatus ? "error" : "info"}
            variant="text"
            onClick={changeUserStatusHandler}
          >
            {activeStatus ? "Block" : "Unblock"}
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

CustomerTableBody.propTypes = {
  customer: PropTypes.object.isRequired,
  selectedCustomerIds: PropTypes.array.isRequired,
  handleSelectOne: PropTypes.func.isRequired,
};

export default CustomerTableBody;

import { Navigate } from "react-router-dom";
import DashboardLayout from "src/components/DashboardLayout";
import MainLayout from "src/components/MainLayout";
import Account from "src/pages/Account";
import CustomerList from "src/pages/CustomerList";
// import Dashboard from "src/pages/Dashboard";
import Login from "src/pages/Login";
import NotFound from "src/pages/NotFound";
import ProductList from "src/pages/ProductList";
import Settings from "src/pages/Settings";
import OrderList from "src/pages/OrderList";
import DealList from "src/pages/DealList";

const routes = [
  {
    path: "app",
    element: <DashboardLayout />,
    children: [
      // { path: "dashboard", element: <Dashboard /> },
      { path: "customers", element: <CustomerList /> },
      { path: "orders", element: <OrderList /> },
      { path: "deals", element: <DealList /> },
      { path: "account", element: <Account /> },
      { path: "products", element: <ProductList /> },
      { path: "settings", element: <Settings /> },
      { path: "/", element: <Navigate to="/app/customers" /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "login", element: <Login /> },
      { path: "404", element: <NotFound /> },
      { path: "/", element: <Navigate to="/login" /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

export default routes;

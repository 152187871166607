import { types } from "./dealsActions";

const initialState = {
  deals: {
    data: [],
    page: 0,
    count: 0,
    size: 25,
  },
};

export const dealsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.INITIAL_DEALS_LOAD_SUCCESS:
      return {
        ...state,
        deals: action.deals,
      };

    case types.DELETE_DEAL:
      const newDeals = [...state.deals.data].filter(
        (el) => el.id !== action.id
      );
      return {
        ...state,
        deals: { ...state.deals, data: newDeals },
      };

    default:
      return state;
  }
};

import server from "../utils/server";

// Auth apis
export function signIn(data) {
  return server({
    url: "/auth/login",
    method: "post",
    data: data,
    datatype: "json",
  });
}

// Costomers apis
export function getUserInfo() {
  return server({
    url: "/user",
    method: "get",
    params: {},
    datatype: "json",
  });
}
export function blockUser(userId) {
  return server({
    url: `/users/${userId}/block`,
    method: "post",
    data: {},
    datatype: "json",
  });
}
export function unblockUser(userId) {
  return server({
    url: `/users/${userId}/unblock`,
    method: "post",
    params: {},
    datatype: "json",
  });
}

// Users api
export function getUserList(data) {
  return server({
    url: "/users",
    method: "get",
    params: data,
    datatype: "json",
  });
}

// Deals apis
export function getDealList(data) {
  return server({
    url: "/deals",
    method: "get",
    params: data,
    datatype: "json",
  });
}

export function deleteDeal(id) {
  return server({
    url: `/deals/${id}`,
    method: "delete",
    data: {},
    datatype: "json",
  });
}

// Orders
export function getOrderList(data) {
  return server({
    url: "/orders",
    method: "get",
    params: data,
    datatype: "json",
  });
}

export const types = {
  INITIAL_DEALS_LOAD: "DEALS/INITIAL_DEALS_LOAD",
  INITIAL_DEALS_LOAD_SUCCESS: "DEALS/INITIAL_DEALS_LOAD_SUCCESS",
  DELETE_DEAL: "DEALS/DELETE_DEAL",
};

export const initialDealsLoadAction = () => {
  return {
    type: types.INITIAL_DEALS_LOAD,
  };
};

export const initialDealsLoadSuccessAction = (deals) => {
  return {
    type: types.INITIAL_DEALS_LOAD_SUCCESS,
    deals,
  };
};

export const deleteDealAction = (id) => {
  return {
    type: types.DELETE_DEAL,
    id,
  };
};

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import {
  initialDealsLoadAction,
  deleteDealAction,
} from "src/redux/deals/dealsActions";
import {
  getPageNum,
  getPageSize,
  getDeals,
  getTotalPages,
} from "src/redux/deals/dealsSelectors";
import { deleteDeal } from "src/api";
import getUrlParams from "src/utils/getUrlParams";
import DealTableBody from "./DealTableBody";

const DealListResults = ({ ...rest }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const deals = useSelector(getDeals);
  const page = useSelector(getPageNum);
  const pageSize = useSelector(getPageSize);
  const totalPages = useSelector(getTotalPages);

  const [showModal, setShowModal] = useState(false);
  const [dealId, setDealId] = useState();

  useEffect(() => {
    const query = getUrlParams();

    if (page !== (query?.page || 1)) dispatch(initialDealsLoadAction());
  }, [dispatch]);

  const handleLimitChange = (event) => {
    navigate(`/app/deals?page=1&size=${event.target.value}`);
    dispatch(initialDealsLoadAction());
  };

  const handlePageChange = (event, newPage) => {
    navigate(`/app/deals?page=${newPage + 1}&size=${pageSize}`);
    dispatch(initialDealsLoadAction());
  };

  const openDeleteDealModal = (id) => {
    setShowModal(true);
    setDealId(id);
  };

  const deleteDealHandler = async () => {
    dispatch(deleteDealAction(dealId));

    setShowModal(false);
    setDealId(null);

    await deleteDeal(dealId);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <DialogTitle>{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Delete this deal forever?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowModal(false)}
            sx={{
              color: "text.primary",
            }}
          >
            Cancel
          </Button>
          <Button onClick={deleteDealHandler} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Card {...rest}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Username</TableCell>
                  <TableCell align="center" style={{ minWidth: "80px" }}>
                    Deal ID
                  </TableCell>
                  <TableCell>Dealname</TableCell>
                  <TableCell>CreatDate</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Link</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {deals.map((deal) => (
                  <DealTableBody
                    key={deal.id}
                    data={deal}
                    openDeleteDealModal={openDeleteDealModal}
                  />
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        {deals.length > 0 && (
          <TablePagination
            component="div"
            count={totalPages}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page - 1}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[25, 50]}
          />
        )}
      </Card>
    </>
  );
};

export default DealListResults;

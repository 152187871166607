import { types } from "./ordersActions";

const initialState = {
  orders: {
    data: [],
    page: 0,
    count: 0,
    size: 25,
  },
};

export const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.INITIAL_ORDERS_LOAD_SUCCESS:
      return {
        ...state,
        orders: action.orders,
      };

    default:
      return state;
  }
};

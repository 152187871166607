import { types } from "./usersActions";

const initialState = {
  users: {
    data: [],
    page: 0,
    count: 0,
    size: 25,
  },
};

export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.INITIAL_USERS_LOAD_SUCCESS:
      return {
        ...state,
        users: action.users,
      };

    default:
      return state;
  }
};

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { initialOrdersLoadAction } from "src/redux/orders/ordersActions";
import {
  getPageNum,
  getPageSize,
  getOrders,
  getTotalPages,
} from "src/redux/orders/ordersSelectors";
import OrderTableBody from "./OrderTableBody";
import getUrlParams from "src/utils/getUrlParams";

const OrderListResults = ({ ...rest }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const orders = useSelector(getOrders);
  const page = useSelector(getPageNum);
  const pageSize = useSelector(getPageSize);
  const totalPages = useSelector(getTotalPages);

  const [selectedOrderIds, setSelectedOrderIds] = useState([]);

  useEffect(() => {
    const query = getUrlParams();

    if (page !== (query?.page || 1)) dispatch(initialOrdersLoadAction());
  }, [dispatch]);

  const handleSelectAll = (event) => {
    let newSelectedOrderIds;

    if (event.target.checked) {
      newSelectedOrderIds = orders.map((order) => order.id);
    } else {
      newSelectedOrderIds = [];
    }

    setSelectedOrderIds(newSelectedOrderIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedOrderIds.indexOf(id);
    let newSelectedOrderIds = [];

    if (selectedIndex === -1) {
      newSelectedOrderIds = newSelectedOrderIds.concat(selectedOrderIds, id);
    } else if (selectedIndex === 0) {
      newSelectedOrderIds = newSelectedOrderIds.concat(
        selectedOrderIds.slice(1)
      );
    } else if (selectedIndex === selectedOrderIds.length - 1) {
      newSelectedOrderIds = newSelectedOrderIds.concat(
        selectedOrderIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedOrderIds = newSelectedOrderIds.concat(
        selectedOrderIds.slice(0, selectedIndex),
        selectedOrderIds.slice(selectedIndex + 1)
      );
    }

    setSelectedOrderIds(newSelectedOrderIds);
  };

  const handleLimitChange = (event) => {
    navigate(`/app/orders?page=1&size=${event.target.value}`);
    dispatch(initialOrdersLoadAction());
  };

  const handlePageChange = (event, newPage) => {
    navigate(`/app/orders?page=${newPage + 1}&size=${pageSize}`);
    dispatch(initialOrdersLoadAction());
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedOrderIds.length === orders.length}
                    color="primary"
                    indeterminate={
                      selectedOrderIds.length > 0 &&
                      selectedOrderIds.length < orders.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>Order No.</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Affiliate</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Cashback</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order) => (
                <OrderTableBody
                  key={order.id}
                  data={order}
                  selectedOrderIds={selectedOrderIds}
                  handleSelectOne={handleSelectOne}
                />
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      {orders.length > 0 && (
        <TablePagination
          component="div"
          count={totalPages}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page - 1}
          rowsPerPage={pageSize}
          rowsPerPageOptions={[25, 50]}
        />
      )}
    </Card>
  );
};

export default OrderListResults;

import { takeLatest, put, call } from "redux-saga/effects";
import { types, initialUsersLoadSuccessAction } from "./usersActions";
import {
  showLoader,
  hideLoader,
  setAlertAction,
} from "src/redux/app/appActions";
import { getUserList } from "src/api";
import getUrlParams from "src/utils/getUrlParams";

export function* usersWatcher() {
  yield takeLatest(types.INITIAL_USERS_LOAD, initialUsersLoadSaga);
}

function* initialUsersLoadSaga() {
  try {
    yield put(showLoader());

    const usersData = yield call(fetchUsersData);

    yield put(initialUsersLoadSuccessAction(usersData));

    yield put(hideLoader());
  } catch (error) {
    yield put(
      setAlertAction({
        status: "error",
        message: "load error",
      })
    );
    yield put(hideLoader());
  }
}

const fetchUsersData = async () => {
  const query = getUrlParams();
  const { data: usersData } = await getUserList({
    size: query?.size || 25,
    page: query?.page || 1,
  });

  return usersData;
};

import { combineReducers } from "redux";
import { appReducer } from "./app/appReducer";
import { usersReducer } from "./users/usersReducer";
import { ordersReducer } from "./orders/ordersReducer";
import { dealsReducer } from "./deals/dealsReducer";

export const rootReducer = combineReducers({
  app: appReducer,
  userList: usersReducer,
  orderList: ordersReducer,
  dealList: dealsReducer,
});

import { takeLatest, put, call } from "redux-saga/effects";
import { types, initialOrdersLoadSuccessAction } from "./ordersActions";
import {
  showLoader,
  hideLoader,
  setAlertAction,
} from "src/redux/app/appActions";
import { getOrderList } from "src/api";
import getUrlParams from "src/utils/getUrlParams";

export function* ordersWatcher() {
  yield takeLatest(types.INITIAL_ORDERS_LOAD, initialOrdersLoadSaga);
}

function* initialOrdersLoadSaga() {
  try {
    yield put(showLoader());

    const ordersData = yield call(fetchOrdersData);

    yield put(initialOrdersLoadSuccessAction(ordersData));

    yield put(hideLoader());
  } catch (error) {
    yield put(
      setAlertAction({
        status: "error",
        message: "load error",
      })
    );
    yield put(hideLoader());
  }
}

const fetchOrdersData = async () => {
  const query = getUrlParams();
  const { data: ordersData } = await getOrderList({
    size: query?.size || 25,
    page: query?.page || 1,
  });

  return ordersData;
};

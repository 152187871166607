export const types = {
  INITIAL_USERS_LOAD: "USERS/INITIAL_USERS_LOAD",
  INITIAL_USERS_LOAD_SUCCESS: "USERS/INITIAL_USERS_LOAD_SUCCESS",
};

export const initialUsersLoadAction = () => {
  return {
    type: types.INITIAL_USERS_LOAD,
  };
};

export const initialUsersLoadSuccessAction = (users) => {
  return {
    type: types.INITIAL_USERS_LOAD_SUCCESS,
    users,
  };
};

export const types = {
  INITIAL_ORDERS_LOAD: "ORDERS/INITIAL_ORDERS_LOAD",
  INITIAL_ORDERS_LOAD_SUCCESS: "ORDERS/INITIAL_ORDERS_LOAD_SUCCESS",
};

export const initialOrdersLoadAction = () => {
  return {
    type: types.INITIAL_ORDERS_LOAD,
  };
};

export const initialOrdersLoadSuccessAction = (orders) => {
  return {
    type: types.INITIAL_ORDERS_LOAD_SUCCESS,
    orders,
  };
};

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { Formik } from "formik";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from "@material-ui/core";
import { signIn } from "../api";
import { useAppContext } from "src/context/AppContext";
import { useRequireAuth } from "src/hooks/useRequireAuth.hook";
import { setAlertAction } from "src/redux/app/appActions";

const Login = () => {
  useRequireAuth(true, "/app/customers");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { login } = useAppContext();

  return (
    <>
      <Helmet>
        <title>Login | Dealtree</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              login: "",
              password: "",
            }}
            onSubmit={async (data) => {
              try {
                const { data: userInfo } = await signIn({
                  login: data.login,
                  password: data.password,
                });
                dispatch(
                  setAlertAction({
                    status: "info",
                    message: `Welcome back ${userInfo?.data?.nameSlug}!`,
                  })
                );
                login(userInfo);

                setTimeout(() => {
                  navigate("/app/customers", { replace: true });
                }, 1000);
              } catch ({ data: error }) {
                dispatch(
                  setAlertAction({
                    status: "error",
                    message: error.message,
                  })
                );
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }} textAlign="center">
                  <img src="" alt="" />
                  <Typography color="textPrimary" gutterBottom variant="h2">
                    Management System
                  </Typography>
                </Box>

                <TextField
                  error={Boolean(touched.login && errors.login)}
                  fullWidth
                  helperText={touched.login && errors.login}
                  label="Username/Email"
                  margin="normal"
                  name="login"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.login}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Login;

import { Helmet } from "react-helmet";
import { Box, Container } from "@material-ui/core";
import DealListResults from "src/components/deal/DealListResults";

const DealList = () => (
  <>
    <Helmet>
      <title>Deals | Dealtree</title>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Box sx={{ pt: 3 }}>
          <DealListResults />
        </Box>
      </Container>
    </Box>
  </>
);

export default DealList;

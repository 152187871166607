import { takeLatest, put, call } from "redux-saga/effects";
import { types, initialDealsLoadSuccessAction } from "./dealsActions";
import {
  showLoader,
  hideLoader,
  setAlertAction,
} from "src/redux/app/appActions";
import { getDealList } from "src/api";
import getUrlParams from "src/utils/getUrlParams";

export function* dealsWatcher() {
  yield takeLatest(types.INITIAL_DEALS_LOAD, initialDealsLoadSaga);
}

function* initialDealsLoadSaga() {
  try {
    yield put(showLoader());

    const dealsData = yield call(fetchDealsData);

    yield put(initialDealsLoadSuccessAction(dealsData));

    yield put(hideLoader());
  } catch (error) {
    yield put(
      setAlertAction({
        status: "error",
        message: "load error",
      })
    );
    yield put(hideLoader());
  }
}

const fetchDealsData = async () => {
  const query = getUrlParams();
  const { data: dealsData } = await getDealList({
    size: query?.size || 25,
    page: query?.page || 1,
  });

  return dealsData;
};

import PropTypes from "prop-types";
import { Checkbox, TableCell, TableRow } from "@material-ui/core";

const OrderTableBody = ({ data, selectedOrderIds, handleSelectOne }) => {
  const returnStatus = (status) => {
    switch (status) {
      case 0:
        return "Pending";
      case 10:
        return "Available";
      case -20:
        return "Canceled";

      default:
        return "";
    }
  };

  return (
    <>
      <TableRow hover selected={selectedOrderIds.indexOf(data.id) !== -1}>
        <TableCell padding="checkbox">
          <Checkbox
            checked={selectedOrderIds.indexOf(data.id) !== -1}
            onChange={(event) => handleSelectOne(event, data.id)}
            value="true"
          />
        </TableCell>
        <TableCell>{data.orderNo}</TableCell>
        <TableCell>{data?.user?.email}</TableCell>
        <TableCell>{data.affiliation}</TableCell>
        <TableCell>${data.amount}</TableCell>
        <TableCell>${data.userCashback}</TableCell>
        <TableCell>{returnStatus(data.status)}</TableCell>
      </TableRow>
    </>
  );
};

OrderTableBody.propTypes = {
  data: PropTypes.object.isRequired,
  selectedOrderIds: PropTypes.array.isRequired,
  handleSelectOne: PropTypes.func.isRequired,
};

export default OrderTableBody;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { initialUsersLoadAction } from "src/redux/users/usersActions";
import {
  getPageNum,
  getPageSize,
  getUsers,
  getTotalPages,
} from "src/redux/users/usersSelectors";
import CustomerTableBody from "./CustomerTableBody";
import getUrlParams from "src/utils/getUrlParams";

const CustomerListResults = ({ ...rest }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const customers = useSelector(getUsers);
  const page = useSelector(getPageNum);
  const pageSize = useSelector(getPageSize);
  const totalPages = useSelector(getTotalPages);
  console.log(customers.length);

  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);

  useEffect(() => {
    const query = getUrlParams();

    if (page !== (query?.page || 1)) dispatch(initialUsersLoadAction());
  }, [dispatch]);

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = customers.map((customer) => customer.id);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(1)
      );
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event) => {
    navigate(`/app/customers?page=1&size=${event.target.value}`);
    dispatch(initialUsersLoadAction());
  };

  const handlePageChange = (event, newPage) => {
    navigate(`/app/customers?page=${newPage + 1}&size=${pageSize}`);
    dispatch(initialUsersLoadAction());
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedCustomerIds.length === customers.length}
                    color="primary"
                    indeterminate={
                      selectedCustomerIds.length > 0 &&
                      selectedCustomerIds.length < customers.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>Profile</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>User ID</TableCell>
                <TableCell>Registration date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers.map((customer) => (
                <CustomerTableBody
                  key={customer.id}
                  customer={customer}
                  selectedCustomerIds={selectedCustomerIds}
                  handleSelectOne={handleSelectOne}
                />
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      {customers.length > 0 && (
        <TablePagination
          component="div"
          count={totalPages}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page - 1}
          rowsPerPage={pageSize}
          rowsPerPageOptions={[25, 50]}
        />
      )}
    </Card>
  );
};

export default CustomerListResults;

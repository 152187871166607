import { Helmet } from "react-helmet";
import { Box, Container } from "@material-ui/core";
import CustomerListResults from "src/components/customer/CustomerListResults";
// import CustomerListToolbar from "src/components/customer/CustomerListToolbar";

const CustomerList = () => (
  <>
    <Helmet>
      <title>Customers | Dealtree</title>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
    <Box
      sx={{
        backgroundColor: "background.default",
        minHeight: "100%",
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        {/* <CustomerListToolbar /> */}
        <Box sx={{ pt: 3 }}>
          <CustomerListResults />
        </Box>
      </Container>
    </Box>
  </>
);

export default CustomerList;

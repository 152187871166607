import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getErrorInfo } from "src/redux/app/appSelectors";
import { setAlertAction } from "src/redux/app/appActions";
import { Alert, Snackbar } from "@material-ui/core";

const AppAlert = () => {
  const dispatch = useDispatch();
  const alert = useSelector(getErrorInfo);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(
      setAlertAction({
        status: "",
        message: "",
      })
    );
  };

  if (!alert?.message) return null;

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        key={"top" + "right"}
        open={!!alert?.message}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={alert.status}>
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AppAlert;

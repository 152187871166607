import PropTypes from "prop-types";
import { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useAppContext } from "src/context/AppContext";
import { AppBar, Badge, Box, IconButton, Toolbar } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import LogoutIcon from "@material-ui/icons/Logout";
import Logo from "./Logo";

const DashboardNavbar = ({ onMobileNavOpen, ...rest }) => {
  const [notifications] = useState([]);
  const navigate = useNavigate();
  const { token, logout } = useAppContext();

  const logoutHandler = () => {
    logout();

    navigate("/");
  };

  return (
    <AppBar elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to={!!token ? "/app/customers" : "/login"}>
          <Logo />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: { xs: "none", lg: "block", xl: "block" } }}>
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton color="inherit" onClick={logoutHandler}>
            <LogoutIcon />
          </IconButton>
        </Box>
        <Box sx={{ display: { xs: "block", lg: "none", xl: "none" } }}>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

DashboardNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func,
};

export default DashboardNavbar;
